import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {Form, Col, Row, Spin, TreeSelect} from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import NumericInput from 'components/common/numericInput';
import Input from 'components/common/input';

import { createAgent, getAgentRegistrationForm, getAgents } from "store/actions/dashboard/agentSystem/agents/agents.action";
import { getCompanyPasswordSettings } from "store/actions/dashboard/companies/passwordSettings.action";
import { getAgentCurrencies } from "store/actions/dashboard/agentSystem/agents/currencies.action";
import {getProjectCurrencies, getProjectSubProjects} from "store/actions/dashboard/projects/currencies.action";

import Paths from 'constants/path.constants';

import { POPUP_SIZE } from "constants/common.constants";
import { COMPANY_CONTROL_RULE, COMPANY_CONTROL_TYPE } from 'constants/company.constants';
import { USER_GENDER, USER_ROLE, USER_STATE, USER_TYPE } from 'constants/user.constants';
import { EMAIL_REGEX, TEL_REGEX, USERNAME_REGEX, ADDRESS_REGEX, LAST_NAME_REGEX, FIRST_NAME_REGEX } from "constants/regex.constants";
import { CURRENCY_STATE } from "constants/currency.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { toLowerCaseFirstLetter, isFranchisingMode, flagsToBinary, getGlobalProjectData } from "utils/common";
import { validatePassword } from "utils/password";
import { getUser } from 'utils/auth';
import { hasPermission } from 'utils/permissions';
import { hasAgentEditPageAccess } from "utils/pageAccess"

import useAutosuggestion from 'hooks/useAutosuggestion';

import registrationFormType from "types/project/registrationForm.type";
import companyPasswordSettingsType from "types/company/passwordSettings.type";
import userInfoType from 'types/profile/userInfo.type';
import currencyType from "types/currency/currency.type";

import countries from "systemData/countries";
import useProjectType from "hooks/useProjectType";

/** Agent Creating Popup Component */
const AgentCreateComponent = ({
    isSaving,
    getAgents,
    createAgent,
    isRegistrationFormLoading,
    registrationForm,
    getAgentRegistrationForm,
    getCompanyPasswordSettings,
    passwordSettings,
    globalCompanyId,
    globalProjectId,
    userInfo,
    getAgentCurrencies,
    getProjectCurrencies,
    getProjectSubProjects,
    agentCurrencies,
    projectCurrencies,
    onClose,
    subProjects
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue, setFieldsValue } = formInstance;
    const navigate = useNavigate();
    const { hasLiteMode, hasLatamMode } = useProjectType();

    const [selectedParent, setSelectedParent] = useState(getUser()?.role === USER_ROLE.ADMIN ? null : userInfo.id);

    const shouldUseProjectCurrencies = !selectedParent && getUser()?.role === USER_ROLE.ADMIN;

    const { name: globalProjectName } = getGlobalProjectData(globalProjectId);

    const currencies = getUser()?.role === USER_ROLE.ADMIN ? projectCurrencies : agentCurrencies;
    const hasOneCurrency = currencies.length === 1;

    /** Load Registration Form, Password Settings */
    useEffect(() => {
        getAgentRegistrationForm();
        getCompanyPasswordSettings();

        if (hasLatamMode) {
            getProjectSubProjects();
        }
    }, [])

    /** Get Agents */
    const hasParentField = registrationForm.some(control => control.name === "ParentId" && control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN);

    const [agentNames] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        dependencies: [registrationForm],
        actionParams: { userRole: USER_TYPE.AGENT, state: flagsToBinary([USER_STATE.ACTIVE, USER_STATE.IN_PROGRESS, USER_STATE.EXPIRED]) },
        disable: !hasParentField
    });

    /** Load Currencies */
    useEffect(() => {
        if (
            registrationForm.some(control => control.name === "CurrencyCodes" && control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN
            )
        ) {
            if (shouldUseProjectCurrencies) {
                getProjectCurrencies()
            } else {
                getAgentCurrencies(selectedParent ? selectedParent : userInfo.id);
            }

            setFieldsValue({
                currencyCodes: []
            })
        }
    }, [selectedParent, registrationForm])


    /** Set Default agent */
    useEffect(() => {
        if (getParentDropdownRule() !== COMPANY_CONTROL_RULE.HIDDEN) {
            if (getParentDropdownRule() === COMPANY_CONTROL_RULE.REQUIRED) {
                if (agentNames.length > 0) {
                    setFieldsValue({
                        "parentId": agentNames[0].id
                    })
                    setSelectedParent(agentNames[0].id)
                }
            } else {
                setFieldsValue({
                    "parentId": ""
                })
                setSelectedParent("")
            }
        }

    }, [agentNames])

    /** Fires when form submitted
       * @function
       * @memberOf AgentCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const requestBody = {
                    ...data,
                    parentId: data.parentId || null,
                }

                if (hasOneCurrency) {
                    requestBody.currencyCodes = [currencies[0].currency || currencies[0].code];
                }

                if (!isFranchisingMode()) {
                    requestBody.role = USER_TYPE.AGENT;
                }

                createAgent(
                    requestBody,
                    record => {
                        let path = !isFranchisingMode() ? Paths.AGENTS_EDIT : Paths.BETSHOP_OWNERS_EDIT;
                        path = `${path}/${record.id}?cid=${globalCompanyId}&pid=${globalProjectId}&name=${record.userName}&role=${record.role}`;

                        if (hasLiteMode) {
                            return navigate(`${path}#tab=0`);
                        }

                        if (hasPermission({ resource: PERMISSION_RESOURCE.AGENT_PERMISSIONS, action: PERMISSION_ACTION.VIEW })) {
                            navigate(`${path}#tab=5`);
                        } else if (hasAgentEditPageAccess(record.role)) {
                            navigate(path);
                        } else {
                            getAgents()
                        }

                        onClose();
                    });
            }).catch(() => { })
    }

    /** Get field max length
       * @function
       * @param {object} - control
       * @returns {number}
       * @memberOf AgentCreateComponent
   */
    const getFieldMaxLength = control => {
        switch (control.name) {
            case "UserName":
                return 30;
            case "FirstName":
            case "LastName":
            case "MiddleName":
                return 48;
            case "PhoneNumber":
                return 18;
            case "Address":
                return 102;
            case "Password":
                return passwordSettings.passwordMaxLimit || 24;
            default:
                return undefined;
        }
    }

    /** Get rules for control
       * @function
       * @param {object} - control
       * @returns {array}
       * @memberOf AgentCreateComponent
   */
    const getRulesForField = control => {
        const rules = [];

        if (control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED) {
            if (control.name !== "ParentId") {
                if (control.controlType === COMPANY_CONTROL_TYPE.SELECT) {
                    rules.push({ required: true, message: t('backoffice.validation.fieldRequired') })
                } else {
                    rules.push({ required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') })
                }
            }
        }

        switch (control.name) {
            case "UserName":
                rules.push({ max: getFieldMaxLength(control), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ min: 5, message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: USERNAME_REGEX, message: t('backoffice.validation.fieldInvalid') },)
                break;
            case "Password":
                rules.push({ max: getFieldMaxLength(control), message: t('backoffice.validation.fieldInvalid') });
                rules.push(({ getFieldValue }) => ({
                    validator(rule, value) {
                        return validatePassword(value, passwordSettings);
                    }
                }));
                break;
            case "ConfirmPassword":
                rules.push(
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (value !== getFieldValue("password")) {
                                return Promise.reject(t('backoffice.validation.passwordsDoNotMatch'))
                            }
                            return Promise.resolve();
                        }
                    })
                )
                break;
            case "Email":
                rules.push({ pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') });
                break;
            case "FirstName":
                rules.push({ max: getFieldMaxLength(control), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: FIRST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            case "LastName":
                rules.push({ max: getFieldMaxLength(control), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: LAST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            case "MiddleName":
                rules.push({ max: getFieldMaxLength(control), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: LAST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') })
                break;
            case "PhoneNumber":
                rules.push({ max: getFieldMaxLength(control), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: TEL_REGEX, message: t('backoffice.validation.telFormat') });
                break;
            case "Address":
                rules.push({ max: getFieldMaxLength(control), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: ADDRESS_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            case "ParentId":
                if (getParentDropdownRule() === COMPANY_CONTROL_RULE.REQUIRED) {
                    rules.push({ required: true, message: t('backoffice.validation.fieldRequired') })
                }
                break;
            default:
                break;
        }

        return rules;
    }

    /** Get data for select control
       * @function
       * @param {object} - control
       * @returns {array}
       * @memberOf AgentCreateComponent
   */
    const getSelectData = control => {
        const data = {
            "Country": {
                items: countries.map(c => ({
                    value: c.iso2,
                    text: t(`backoffice.countries.${c.iso2}`)
                })),
                showSearch: true,
                isMultiple: false
            },
            "Gender": {
                items: [
                    { value: USER_GENDER.MALE, text: t("backoffice.common.male") },
                    { value: USER_GENDER.FEMALE, text: t("backoffice.common.female") },
                    { value: USER_GENDER.NONE, text: t("backoffice.common.other") }
                ],
                showSearch: false,
                isMultiple: false
            },
            "ParentId": {
                items: (
                    getParentDropdownRule() === COMPANY_CONTROL_RULE.OPTIONAL
                        ? [{ value: "", text: getUser()?.role === USER_ROLE.ADMIN ? globalProjectName : getUser()?.userName }]
                        : []
                )
                    .concat(agentNames.map(agent => ({
                        value: agent.id,
                        text: agent.name,
                    }))),
                showSearch: true,
                isMultiple: false,
            },
            "CurrencyCodes": {
                items: shouldUseProjectCurrencies ?
                    projectCurrencies
                        .filter(item => item.enabled)
                        .map(cur => (
                            { value: cur.code.toUpperCase(), text: cur.code.toUpperCase() }
                        )) :
                    agentCurrencies.map(cur => (
                        { value: cur.currency.toUpperCase(), text: cur.currency }
                    )),
                showSearch: false,
                isMultiple: true
            },
        };
        return data[control.name];
    }

    /** Should show parent dropdown
       * @function
       * @returns {boolean}
       * @memberOf AgentCreateComponent
    */
    const getParentDropdownRule = () => {
        if (isFranchisingMode()) {
            return COMPANY_CONTROL_RULE.HIDDEN;
        }
        if (getUser()?.role === USER_ROLE.AGENT) {
            if (userInfo?.additionalAccesses?.allowCreateNonDirectAgents ?? false) {
                return COMPANY_CONTROL_RULE.OPTIONAL
            }
        } else if (getUser()?.role === USER_ROLE.ADMIN) {
            if (userInfo?.additionalAccesses?.allowCreate1stLevelAgents ?? false) {
                return COMPANY_CONTROL_RULE.OPTIONAL;
            } else {
                return COMPANY_CONTROL_RULE.REQUIRED;
            }
        }
        return COMPANY_CONTROL_RULE.HIDDEN;
    }

    const mapSubProjects = (subProjects) => {
        return subProjects.map(subProject => ({
            title: subProject.projectName,
            value: subProject.projectId,
            key: subProject.projectId,
            children: subProject.domains.map(domain => ({
                type: 'group',
                title: domain,
                value: domain,
                key: domain,
                disabled: true,
                checkable: false,
                selectable: false
            }))
        }))
    }

    /** If only 1 currency available, set it by default */
    useEffect(() => {
        if (
            registrationForm.some(control => control.name === "CurrencyCodes" && control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN)
        ) {
            const itemsCount = shouldUseProjectCurrencies ? projectCurrencies.filter(item => item.enabled).length : agentCurrencies.length;
            if (itemsCount === 1) {
                const currencyCode = shouldUseProjectCurrencies ? projectCurrencies.filter(item => item.enabled)[0].code.toUpperCase() : agentCurrencies[0].currency.toUpperCase()
                setFieldsValue({
                    currencyCodes: [currencyCode]
                })
            }
        }
    }, [registrationForm, projectCurrencies, agentCurrencies])

    return (
        <Modal
            title={isFranchisingMode() ? t('backoffice.agents.createBetshopOwner') : t('backoffice.agents.createAgent')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.BIG}
        >
            <Spin spinning={isRegistrationFormLoading}>
                <Form
                    className="rt--form"
                    form={formInstance}
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        "phoneNumber": "",
                        "role": USER_TYPE.AGENT,
                        "isSeller": false
                    }}
                >
                    <Row gutter={[16, 0]}>
                        {
                            registrationForm
                                .filter(control => {
                                    if (control.name === 'CurrencyCodes' && hasOneCurrency) {
                                        return false;
                                    }

                                    return (
                                        control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN &&
                                        (control.name !== "ParentId" || getParentDropdownRule() !== COMPANY_CONTROL_RULE.HIDDEN)
                                    );
                                })
                                .map((control, index) => (
                                    <Col
                                        xs={24} sm={12}
                                        key={control.name}
                                    >

                                        {
                                            control.controlType === COMPANY_CONTROL_TYPE.INPUT ?
                                                control.name === "PhoneNumber" ? (
                                                    <Form.Item
                                                        label={`${t('backoffice.registrationform.' + control.name)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                                        name={toLowerCaseFirstLetter(control.name)}
                                                        rules={getRulesForField(control)}
                                                        className='rt--general-form-item'
                                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                        validateFirst
                                                    >
                                                        <NumericInput
                                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                            maxLength={getFieldMaxLength(control)}
                                                            isMobileNumber={true}
                                                        />
                                                    </Form.Item>
                                                ) : (
                                                    <Form.Item
                                                        label={`${t('backoffice.registrationform.' + control.name)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                                        name={toLowerCaseFirstLetter(control.name)}
                                                        rules={getRulesForField(control)}
                                                        className='rt--general-form-item'
                                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                        validateFirst
                                                    >
                                                        <Input
                                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                            maxLength={getFieldMaxLength(control)}
                                                        />
                                                    </Form.Item>

                                                ) : control.controlType === COMPANY_CONTROL_TYPE.PASSWORD ? (
                                                    <Form.Item
                                                        label={`${t('backoffice.registrationform.' + control.name)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                                        name={toLowerCaseFirstLetter(control.name)}
                                                        rules={getRulesForField(control)}
                                                        className='rt--general-form-item'
                                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                        validateFirst
                                                    >
                                                        <Input
                                                            type='Password'
                                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                            maxLength={getFieldMaxLength(control)}
                                                            autoComplete="new-password"
                                                            onPaste={control.name === "ConfirmPassword" ? e => e.preventDefault() : undefined}
                                                            onContextMenu={control.name === "ConfirmPassword" ? e => e.preventDefault() : undefined}
                                                            onCopy={control.name === "ConfirmPassword" ? e => e.preventDefault() : undefined}
                                                            onChange={control.name === "Password" ? () => {
                                                                setTimeout(() => {
                                                                    if (getFieldValue('confirmPassword') !== "")
                                                                        validateFields(['confirmPassword'])
                                                                }, 0)
                                                            } : undefined}
                                                        />
                                                    </Form.Item>

                                                ) : control.controlType === COMPANY_CONTROL_TYPE.SELECT ?
                                                    <Form.Item
                                                        label={`${t('backoffice.registrationform.' + control.name)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                                        name={toLowerCaseFirstLetter(control.name)}
                                                        rules={getRulesForField(control)}
                                                        className='rt--general-form-item'
                                                        validateFirst
                                                    >
                                                        <Select
                                                            options={getSelectData(control).items}
                                                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.registrationform.' + control.name)}`}
                                                            search={getSelectData(control).showSearch}
                                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                            onChange={control.name === "ParentId" ? e => setSelectedParent(e) : undefined}
                                                            isMultiple={getSelectData(control).isMultiple}
                                                        />
                                                    </Form.Item>
                                                    : null
                                        }
                                    </Col>
                                ))
                        }

                        {hasLatamMode && (
                            <>
                                <Col
                                    xs={24} sm={12}
                                    key="isSeller"
                                >
                                    <Form.Item
                                        label={`${t('backoffice.registrationform.' + "isSeller")}`}
                                        name="isSeller"
                                        className='rt--general-form-item'
                                        validateFirst
                                    >
                                        <Select
                                            options={[
                                                {
                                                    text: t('backoffice.registrationform.agent'),
                                                    value: false
                                                },
                                                {
                                                    text: t('backoffice.registrationform.seller'),
                                                    value: true
                                                }
                                            ]}
                                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.registrationform.' + "isSeller")}`}
                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={24} sm={12}
                                    key="isSeller"
                                >
                                    <Form.Item
                                        label={`${t('backoffice.registrationform.' + "subProjectIds")}`}
                                        name="subProjectIds"
                                        className='rt--general-form-item'
                                        validateFirst
                                    >
                                        <TreeSelect
                                            treeCheckable
                                            treeData={mapSubProjects(subProjects)}
                                            treeDefaultExpandAll
                                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.registrationform.' + "subProjects")}`}
                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

/** AgentCreateComponent propTypes
    * PropTypes
*/
AgentCreateComponent.propTypes = {
    /** Redux state property, is true when creating agent request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create agent */
    createAgent: PropTypes.func,
    /** Redux action to get agents */
    getAgents: PropTypes.func,
    /** Redux state property, is true when registration form is loading */
    isRegistrationFormLoading: PropTypes.bool,
    /** Redux state property, represents the registration form  */
    registrationForm: PropTypes.arrayOf(registrationFormType),
    /** Redux action to get registration form */
    getAgentRegistrationForm: PropTypes.func,
    /** Redux action to get password settings */
    getCompanyPasswordSettings: PropTypes.func,
    /** Redux state property, represents company agent system password settings */
    passwordSettings: companyPasswordSettingsType,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux action to get agent currencies */
    getAgentCurrencies: PropTypes.func,
    /** Redux state, represents the currencies of current editing agent  */
    agentCurrencies: PropTypes.arrayOf(currencyType),
    /** Redux state, represents the currencies of current editing project  */
    projectCurrencies: PropTypes.arrayOf(currencyType),
    /** Redux action to get project currencies */
    getProjectCurrencies: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createAgent: (agent, onSuccess) => {
            dispatch(createAgent(agent, onSuccess));
        },

        getAgentRegistrationForm: () => {
            dispatch(getAgentRegistrationForm());
        },

        getCompanyPasswordSettings: () => {
            dispatch(getCompanyPasswordSettings());
        },

        getAgents: () => {
            dispatch(getAgents());
        },

        getAgentCurrencies: id => {
            dispatch(getAgentCurrencies(id, CURRENCY_STATE.ACTIVE));
        },

        getProjectCurrencies: () => {
            dispatch(getProjectCurrencies());
        },

        getProjectSubProjects: () => {
            dispatch(getProjectSubProjects());
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.agents.isSaving,
        registrationForm: state.agents.registrationForm,
        isRegistrationFormLoading: state.agents.isRegistrationFormLoading,
        passwordSettings: state.companies.edit.passwordSettings,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId,
        userInfo: state.profile.userInfo,
        agentCurrencies: state.agents.edit.currencies.currencies,
        projectCurrencies: state.projects.edit.currencies,
        subProjects: state.projects.edit.subProjects,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentCreateComponent)
